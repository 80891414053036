.stickerTd {
  width: 40%;
  padding-left: 8px;
  color: black;
  border: 1px solid cadetblue;
  /* font-size: 0.8vw; */
}

.stickerTdM {
  width: 40%;
  padding-left: 8px;
  color: black;
  border: 1px solid cadetblue;
  /* font-size: 0.8vw; */
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 20px;
}

.item {
  display: flex;
}

.item:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  /* padding-bottom: calc(312/500 * 100%);   */
}

.bottomDiv {
  display: flex;
  width: 250%;
  /* height: 70px; */
  padding: 5px 0px;
  margin: 0px;
}

.dataDivFromSticker {
  width: 60%;
  padding-left: 8px;
  color: black;
  border: 1px solid cadetblue;
  /* font-size: large; */
  word-break: break-all;
}

.dataDivFromStickerM {
  width: 60%;
  padding-left: 8px;
  color: black;
  border: 1px solid cadetblue;
  /* font-size: 0.8vw; */
  word-break: break-all;
}

.bottomDivChild {
  text-align: center;
  margin-top: 2px;
  /* font-size: x-large; */
}

.bottomDivChildM {
  text-align: center;
  /* margin-top: 2px; */
  /* font-size: 0.8vw; */
}

.qrModalStyle {
  box-sizing: border-box;
  border: 2px solid cadetblue;
  padding: 5px 0px;
  display: flex;
  flex-direction: column;
}

.single-border-table > tr > th,
.single-border-table > tr > td {
  border: none !important;
  border-left: 1px solid black !important;
  border-top: 1px solid black !important;
}

.single-border-table > tr > td:last-child {
  border-right: 1px solid black !important;
}

.single-border-table > tr:last-child td {
  border-bottom: 1px solid black !important;
}

.single-border-table > tr:nth-child(2) td.common-header {
  border-top: 0px solid black !important;
}

.single-border-table > tr > td.common-border {
  border-bottom: 1px solid black !important;
}



.stikerFlexlarge{
  display: flex;
  font-size: 10.5px;
  text-align: left;
}
.stikerFlexlarge b:first-child{
  width: 50%;
}
.stikerFlexlarge b:nth-child(2){
  width: 2%;
}
.stikerFlexlarge span{
  width: 63%;
  font-size: 9.5px;
}

.stikerFlexsmall{
  display: flex;
  font-size: 15px;
  text-align: left;
}
.stikerFlexsmall b:first-child{
  width: 30%;
}
.stikerFlexsmall b:nth-child(2){
  width: 5%;
}
.stikerFlexsmall span{
  width: 65%;
  font-size: 14px;
}

.stikerFlexMedium{
  display: flex;
  font-size: 12px;
  text-align: left;
}
.stikerFlexMedium b:first-child{
  width: 45%;
}
.stikerFlexMedium b:nth-child(2){
  width: 5%;
}
.stikerFlexMedium span{
  width: 50%;
  font-size: 11px;
}

.stikerFlexMediumLarge{
  display: flex;
  font-size: 11px;
  text-align: left;
}
.stikerFlexMediumLarge b:first-child{
  width: 45%;
}
.stikerFlexMediumLarge b:nth-child(2){
  width: 5%;
}
.stikerFlexMediumLarge span{
  width: 50%;
}

.stikerlargeHeader{
  display: flex;
  align-items: center;
  background-color: green;
  font-size: 19px;
  color: white;
  font-weight: bold;
}
.stikerlargeHeader div:first-child{
  width: 30%;
  text-align: left;
}
.stikerlargeHeader div:last-child{
  width: 70%;
}
